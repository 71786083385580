.categoryPage {
  display: flex;
  width: 100%;
  padding: 40px 10px 40px 40px;
  justify-content: space-between;
  column-gap: 40px;
}

.categoryList {
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.21);
  border-radius: 8px 8px 0px 0px !important;
  height: fit-content;
  position: sticky;
  top: 40px;
  font-size: 0.9rem;
}
.categoryList p {
  font-family: sans-serif;
}
.category.active {
  background-color: #7ae582;
  color: #ffffff;
}

.category {
  padding: 15px 15px;
  border-bottom: #7ae58160 solid 0.5px;
  transition: all ease 0.3s;
  font-size: 18px;
}
.category:hover {
  background-color: var(--lightGreen) !important;
  cursor: pointer;
  transition: all ease 0.3s;
  color: white;
}
.categoryProducts {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0px !important;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 20px 10px;
}
.productCard {
  width: 194px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  background: #ffffff;
  border-radius: 12px;
  cursor: pointer;
  color: black !important;
  font-size: 14px;
  transition: all ease 0.3s;
  height: fit-content;
}
.productCard:hover {
  transition: all ease 0.3s;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.4));
}
.productImg {
  height: 150px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transition: all ease 0.3s;
}
.productImg:hover {
  /* zoom: 100%; */
  background-size: 115%;
}
.productName p {
  font-family: sans-serif;
}
.addToCartButton {
  text-align: center;
  background: #7ae582;
  border-radius: 8px;
  padding: 5px;
  font-family: sans-serif;
}
.breadCrumb > * {
  color: black !important;
  margin-right: 5px;
  text-transform: capitalize;
}
