.header {
    border: 1px solid aliceblue;
    background: aliceblue;
    height: 10vh;
}
.header h1 {
    margin: 30px;
}
.bigSection {
    display: flex;
    
}
.bar {
    margin: 30px;
}
.bar a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E3F9E5;
    background: #E3F9E5;
    width: 250px;
    height: 50px;
    color: black;
}
.bar a:hover {
    background: #7AE582;
}
.bar ul li {
    list-style-type: none;
}
.visited{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.largeCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    margin-bottom: 5rem;
}
.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    width: 70vw;

    margin-bottom: 20px;
    padding: 10px;

}
.card img {
    margin: 1rem;
}
.card h6 {
    margin: 1rem;
}
.card a {
    color: black;
}
.ipsum {
    flex: 1;
}
.ipsum h3 {
    margin-bottom: 1rem;
}
.visit h4 {
    margin-top: 12rem;
}
/* card 2 */
.card2 {
    display: flex;
    justify-content: space-evenly;
    border: 2px solid white;
    background: white;
}
.card2 img {
    margin: 1rem;
}
.card2 h6 {
    margin: 1rem;
}
.card2 a {
    color: black;
}
.ipsum2 {
    display: grid;
}
.ipsum2 h3 {
    margin-bottom: 1rem;
}
/* card 3 */
.card3 {
    display: flex;
    justify-content: space-evenly;
    border: 2px solid white;
    background: white;
   
}
.card3 img {
    margin: 1rem;
}
.card3 h6 {
    margin: 1rem;
}
.card3 a{
    color: black;
}
.ipsum3 {
    display: grid;
}
.ipsum3 h3 {
    margin-bottom: 1rem;
}
/* card 4 */
.card4 {
    display: flex;
    justify-content: space-evenly;
    border: 2px solid white;
    background: white;
   
}
.card4 img {
    margin: 1rem;
}
.card4 h6 {
    margin: 1rem;
}
.card4 a {
    color: black;
}
.ipsum4 {
    display: grid;
}
.ipsum4 h3 {
    margin-bottom: 1rem;
}
@media screen and (max-width: 1000px) {
    .card {
        width: 54vw;
    }
    .card2 {
        width: 54vw;
    }
    .card3 {
        width: 54vw;
    }
    .card4 {
        width: 54vw;
    }
}