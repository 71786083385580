.recent {
  /* height: 40vh; */
  background: white;
  padding: 2rem;
  margin: 0 1rem 1rem;
}
.shopImg {
  width: 140px;
  object-fit: contain;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid white;
  height: 30vh;
  margin: 1rem;
  color: black;
  text-align: center;
}
.container h6 {
  font-size: 0.9rem;
  font-family: sans-serif;
}
.text {
  font-size: 1.5rem;
  font-family: sans-serif;
}
.best {
  margin: 10px 10px 30px;
  padding: 30px;
  border: 2px solid white;
  background: white;
  /* width: ; */
  
}
.best h4 {
  padding: 10px;
  font-size: 1.5rem;
  font-family: sans-serif;
}
.prices {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
    gap: 10px 12px;
}
.chroma {
  border: 1px solid rgb(239, 234, 234);
  border-radius: 8px;
  width: 200px;
  padding: 10px;
  color: black;
  
  
  /* flex: 1; */
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
/* .chroma > * {
  flex: 1;
} */
/* .chroma img {
  padding: 1rem;
}
.chroma h5 {
  display: grid;
  justify-content: center;
  font-size: 0.9rem;
  font-family: sans-serif;
}
.chroma h6 {
  display: grid;
  justify-content: center;
  font-size: 0.9rem;
  font-family: sans-serif;
} */
.sun {
  border: 8px solid white;
  background: rgb(239, 234, 234);
  border-radius: 10%;
}
.sun img {
  padding: 1rem;
}
.sun h5 {
  display: grid;
  justify-content: center;
  font-size: 0.9rem;
  font-family: sans-serif;
}
.sun h6 {
  display: grid;
  justify-content: center;
  font-size: 0.9rem;
  font-family: sans-serif;
}
.tomato {
  border: 8px solid white;
  background: rgb(239, 234, 234);
  border-radius: 10%;
}
.tomato img {
  padding: 1rem;
}
.tomato h5 {
  display: grid;
  justify-content: center;
  font-size: 0.9rem;
  font-family: sans-serif;
}
.tomato h6 {
  display: grid;
  justify-content: center;
  font-size: 0.9rem;
  font-family: sans-serif;
}
.corn {
  border: 8px solid white;
  background: rgb(239, 234, 234);
  border-radius: 10%;
}
.corn img {
  padding: 1rem;
}
.corn h5 {
  display: grid;
  justify-content: center;
  font-size: 0.9rem;
  font-family: sans-serif;
}
.corn h6 {
  display: grid;
  justify-content: center;
  font-size: 0.9rem;
  font-family: sans-serif;
}

@media screen and (max-width: 1000px) {
  .container img {
    width: 10vw;
  }
  .prices img {
    width: 20vw;
  }
}
