
.for {
    border: 2px solid rgb(193, 193, 193);
    padding: 20px 0px;
    background: #F5F5F5;
    display: flex;
    justify-content: space-evenly; 
}
.place {
    border: 2px solid white;
    background: white;
    padding: 15px;
    width: 60vw;
    height: 16vh;
    margin-left: 2rem;
    margin-top: 2rem;
}
.place h3 {
    margin-left: 2rem;
    font-size: 1rem;
    font-family: sans-serif;
    font-weight: 300;
}
.master {
    border: 2px solid white;
    background: white;
    margin-left: 2rem;
    margin-top: 2rem;
    width: 60vw;
    padding: 20px;
}
.num h3 {
    font-size: 1.5rem;
    font-family: sans-serif;
    font-weight: 300;
    margin-left: 2rem;
}
.verve {
    display: flex;
    font-size: 1rem;
    margin-left: 2rem;
}
.verve h5 {
    font-size: 2rem;
}
.bigContainer {
    border: 2px solid white;
    background: white;
    margin-left: 2rem;
    margin-top: 2rem;
    padding-bottom: 2rem;
}
.form, h3 {
    font-size: 1rem;
    font-family: sans-serif;
    margin-top: 1rem;
}
.form {
    border: 2px solid white;
    width: 30vw;
    height: 4vh;
    margin-left: 3rem;
    margin-top: 0.8rem;
}
.formContainer h3{
    margin-left: 3rem;
    font-weight: 300;
    font-family: sans-serif;
}
.form {
    border: 2px solid black;
}
.pick h4 {
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 300;
    margin-left: 3rem;
    margin-top: 1rem;
}
.time {
    display: flex;
    gap: 2rem;
    margin-left: 3rem;
}
.time h3 {
    border: 2px solid white;
    background: white;
    padding: 1rem;
    font-size: 0.8rem;   
}
.method {
    margin-top: 2rem;
    margin-right: 2rem;
}
.delivery {
    border: 2px solid white;
    background: white;
    padding: 15px;
}
.delivery h4 {
    font-size: 1.3rem;
    font-family: sans-serif;
    padding-left: 1rem;
}
.total {
    display: grid;
    gap: 2rem;
    padding: 2rem;
    font-size: 1rem;
}
.sub {
    display: flex;
    gap: 6rem;
    margin-left: 3rem;
}
.sub h5 {
    font-weight: 400;
    font-family: sans-serif;
}
.sub p {
    font-weight: 400;
    font-family: sans-serif;
}
.cost {
    display: flex;
    gap: 6rem;
    margin-left: 1rem;
}
.cost h5 {
    font-weight: 400;
    font-family: sans-serif;
}
.cost p {
    font-weight: 400;
    font-family: sans-serif;
}
.able {
    display: flex;
    gap: 6rem;
    margin-left: 1rem;
}
.able h5 {
    font-weight: 400;
    font-family: sans-serif;
}
.able p {
    font-weight: 400;
    font-family: sans-serif;
}
.pay {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}
.pay h4 {
    border: 2px solid #7AE582;
    padding: 1rem;
    font-size: 1rem;
    font-family: sans-serif;
    background: #7AE582;
}
.condition {
    padding: 1rem;
    margin-left: 1rem;
}
.modal{
    padding: 70px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 15px;
}
.modal > article{
    margin-bottom: 20px;
}
@media screen and (max-width: 400px) {
    body {
        height: 200vh;
    }
    /*.for {
        display: flex;
        flex-direction: column;
    }*/
    .bigContainer {
        height: 30vh;
        display: grid;
        justify-content: center;
        padding: 1rem;
    }
    .delivery {
        height: vh;
    }
    .form, h3 {
        font-size: 0.8rem;
        font-family: sans-serif;
        margin-top: 1rem;
    }
    .place {
        padding: 1rem;
        height: 10vh;
    }
    .place h3 {
        font-size: 0.8rem;
        font-family: sans-serif;
        font-weight: 300;
    }
    .verve h5 {
        font-size: 0.8rem;
        font-family: sans-serif;
        font-weight: 300;
        margin-bottom: 2rem;
    }
}
@media screen and (max-width: 1000px){
    .for {
       display: flex;
    }
    .place {
        width: 40vw;
    }
    .master {
        width: 40vw;
    }
    .bigContainer {
        width: vw;
    }
}