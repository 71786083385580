.ForgotPassword {
  height: calc(100vh - 75px);
  display: flex;
}
.left-banner {
  width: 50%;
  height: 100%;
  background-image: url(./Pictures/F10.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.rightForm {
    width: 50%;
  padding: 4rem 5rem;
}

.form-title {
  margin-bottom: 3rem;
}
.form{
    width: 100%;
}
.emailInput {
  border: none;
  border-bottom: 0.5px solid black;
  background: transparent;
  
  width: 80%;
  margin-bottom: 20px;
  outline: none !important;
  padding: 5px 10px;
}
.emailBox h3 {
  margin-bottom: 10px;
}
.button {
  border: none;
  background: var(--lightGreen);
  display: flex;
   width: 80%;
  border-radius: 8px;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  color: black;
}
.error {
  color: rgb(153, 16, 16);
}
