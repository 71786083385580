.bigFooter {
    display: flex;
    /* gap: 3rem; */
    justify-content: space-evenly;
    /* align-items: center; */
    font-style: sans-serif;
    padding: 100px;
    background: #1D281E;
}
.bigFooter a {
    font-family: sans-serif;
    font-size: 0.9rem;
    color: aliceblue;
    padding: 30px;
}
.some {
    display: flex;
    flex-direction: column;
}
.other {
    display: flex;
    flex-direction: column;
}
.letter {
    display: flex;
    flex-direction: column;
}
.letter:first-child {
  font-family: "Rubik" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 40px !important;
}
.subscribe:first-child {
  font-family: "Rubik" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 40px !important;
}
.subscribe {
  display: flex;
}
.subscribe-btn {
  background: black !important;
}

.editText {
  background-color: transparent;
  outline: none;
  border: none;
  
  border-bottom: 0.5px white solid;
  padding: 7px;
  color: white;
}
