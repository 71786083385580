.autum {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(./Pictures/F1.png);
    background-blend-mode: darken, normal;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    letter-spacing: 0.015em;
    height: 300px;
    margin: 20px;
}
.F1 img{
    filter: blur(3px);
    width: 97vw;
    height: 38vh;
    padding: 20px;
}
.shop {
    display: grid;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.made h1 {
    font-family: 'Rubik Wet Paint', cursive;
    text-align: center;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
}
.made h2 {
    font-family: 'Rubik Wet Paint' ;
}
.shop h1 {
    color: aliceblue;
    font-size: 3rem;
}
.shop h2 {
    color: aliceblue;
    font-size: 3rem;
}
.buttons {
    display: flex;
    justify-content: space-evenly;
    column-gap: 2rem;
}
.buttons h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid rgb(119, 180, 119);
    height: 50px;
    width: 160px;
    border-radius: 10px;
    font-size: medium;
    font-family: sans-serif;
    color: rgb(119, 180, 119); 
}
.find h3 {
    width: 170px;
}
.savedStore h3 {
    width: 130px;
}
@media screen and (max-width: 1000px) {
    .shop h1 {
        color: rgb(119, 180, 119);
        font-size: 2rem;
    }
    .shop h2 {
        color: rgb(119, 180, 119);
        font-size: 2rem;
    }
}
