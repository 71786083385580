body {
  background: white;
}
.large {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2rem;
  padding: 20px;
  background: #f5f0f0 !important;
}
.cartItems {
  display: flex;
  flex-direction: column;
  width: 60vw;
  row-gap: 20px;
  border-radius: 10%;
}
.image {
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  gap: 5rem;
  border-radius: 10%;
  padding: 1rem;
  
}

.line {
  background: #ffffff;
  border-radius: 8px;
   color: black !important;
  filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.1));
}

.lines {
  background: #ffffff;
  border-radius: 8px;
  color: black !important;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  width: 80%;
  margin:  auto;
  filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.1));
}
.image h5 {
  font-size: 1rem;
  font-weight: 300;
  border-radius: 10%;
}
.image h4 {
  margin-top: 1rem;
  font-weight: 300;
}
.delete {
  margin-left: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
  /* margin-left: 80%; */
  padding: 10px 10px 10px;
  display: flex;
  flex-wrap: nowrap;
  color: white;
  border-radius: 8px;
  gap: 7px;
  width: fit-content;
  background-color: #7ae582;
  cursor: pointer;
}
.delete,
h5 {
  font-family: sans-serif;
  font-size: 1rem;
}

.word {
  font-size: 1rem;
  padding: 20px;
  border-radius: 10px;
  background: #ffffff;
  margin-bottom: 1rem;
  position: relative;
   filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.1));
 
}
.total {
  display: grid;
  gap: 1rem;
  padding: 2rem;
}
.sub {
  display: flex;
  justify-content: space-between;
}
.sub h5 {
  font-weight: 400;
  font-family: sans-serif;
}
.sub p {
  font-weight: 400;
  font-family: sans-serif;
}
.cost {
  display: flex;
  justify-content: space-between;
}
.cost h5 {
  font-weight: 400;
  font-family: sans-serif;
}
.cost p {
  font-weight: 400;
  font-family: sans-serif;
}
.total hr {
  width: 20vw;
}
.able {
  display: flex;
  justify-content: space-between;
}
.able h5 {
  font-weight: 600;
  font-family: sans-serif;
}
.able p {
  font-weight: 600;
  font-family: sans-serif;
}
.pay {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}
.pay h4 {
  border: 2px solid white;
  padding: 1rem;
  font-size: 1rem;
  font-family: sans-serif;
  background: white;
}
.checkButton {
  border: 2px solid #7ae582;
  background: #7ae582;
  border-radius: 10px;
  padding: 0.8rem;

}
.checkButton a {
  display: flex;
  justify-content: center;
  font-family: sans-serif;
}
.tie {
    display: grid;
    align-items: center;
    justify-content: center;
}
.tie h4 {
  display: flex;
  justify-content: center;
  font-family: sans-serif;
  font-size: 0.8rem;
}
.pIcon {
  display: flex;
  gap: 1rem;
  padding: 10px;
  font-size: 2.5rem;
}
.tank {
  border: 2px solid #ffffff;
  border-radius: 10px;
  background: #ffffff;
  padding: 2rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.things {
  font-size: 1.5rem;
}
.saved {
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  align-items: center;
  width: fit-content;
}
.palm {
    color: black;
}
.watch {
  width: 210px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.watch h6 {
  font-family: sans-serif;
  font-size: 1rem;
}
.glasses h6 {
  font-family: sans-serif;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}
.belt h6 {
  font-family: sans-serif;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}
.suit h6 {
  font-family: sans-serif;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}
@media (max-width: 400px) {
    body {
        background: red;
    }
    .large {
        display: flex;
        flex-direction: column;
    }
    .image {
        display: flex;
        gap: 3rem;
        padding: 1rem;
    }
    .image h4 {
        font-size: 0.7rem;
    }
    .checkButton {
        width: 30vw;
        height: 10vw;
    }
    .checkButton a {
        font-size: 0.7rem;
        color: black;
    }
    /*.tie {
        display: flex;
        align-items: flex-start;
    }*/
  /*.tie h4 {
        font-size: 0.7rem;
    }
    .pIcon {
        font-size: 1rem;
    }*/
}

@media screen and (max-width: 1000px) {
    .large {
        display: flex;
        flex-direction: column;
    }
    .line {
        width: 94vw;
    }
    .image {
        display: flex;
        justify-content: space-evenly;
        gap: 5rem;
        padding: 1rem;
    } 
    .grid {
        display: grid;
        justify-content: flex-end;
    }
    .word {
        width: 50vw;
        display: grid;
        align-items: center;
        justify-content: center;
        margin-right: 8rem;
        margin-top: 2rem;
    }
    .checkButton {
        width: 20vw;
    }
    .tie {
        display: grid;
        align-items: center;
        justify-content: center;
        margin-right: 7rem;
    }
}
@media  screen and (max-width: 400px) {
    .word {
        width: 70vw;
    }
    .checkButton {
        width: 30vw;
    }
    .tie {
        display: grid;
        align-items: center;
        justify-content: flex-end;
    }
}

