.iframe {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
iframe {
    width: 80vw;
}
.shoprite {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid white;
    background-color: white;
    margin: 20px;
}
.shoprite h5 {
    display: inherit;
    align-items: center;
}
.shoprite svg {
    display: inherit;
    align-items: center;
}
/* Skymall */
.skymall {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid white;
    background-color: white;
    margin: 20px;
}
.skymall h5 {
    display: inherit;
    align-items: center;
}
.skymall svg {
    display: inherit;
    align-items: center;
}