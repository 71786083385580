* {
    box-sizing: content-box;
    margin: 0;
    padding: 0;
}
.sign img {
    height: 100vh;
    width: 50vw;
}
.field {
    display: flex;
}
.set {
    width: 50vw;
    border-radius: 10px;
}
.all {
    margin-left: 6rem;
    margin-top: 3.5rem;
}
.all h2 {
    font-family: sans-serif;
    font-size: 2rem;
}
.all p {
    font-family: sans-serif;
    font-size: 16px;
    line-height: 16px;    
}
.all a {
    color: black;
}
.user h3 {
    font-size: 16px;
    font-family: sans-serif;
    padding: 5px;
}
.name {
    font-size: 1rem;
    font-weight: 400;
    height: 35px;
    width: 80%;
    border: none;
    border-bottom: solid black 0.5px !important;
    outline: none !important;
    
    background-color: transparent;
    box-shadow: none;
}
.in {
    border: 2px solid #7AE582;
    background: #7AE582;
    width: 80%;
    border-radius: 10px;
    padding: 5px;
    margin-top: 48px;
    margin-bottom: 48px;
    display: flex;
    justify-content: center;
}
.in h2 {
    font-size: 18px;
}
.split {
    display: flex;
    justify-content: space-between;
}
.line1 hr {
    width: 20vw;
}
.split h5 {
    position: relative;
    top: -8px;
    font-size: 0.8rem;
}
.line2 hr {
    width: 20vw;
}
.awesome {
    font-size: 2rem;
    display: flex;
    justify-content: space-evenly;
    margin-top: 4rem;
}
@media screen and (max-width: 1000px) {
    .field {
        display: block;
        position: relative;
    }
    .field img {
        width: 97vw;
    }
    .set {
        width: 97vw;
        position: absolute;
        top: 400px;
        background: white;
    }
    .name {
        font-weight: lighter;
    }
    .split {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .awesome {
        position: relative;
        top: -2rem;
    }
}