:root {
  --deepGreen: #1d281e;
  --lightGreen: #7ae582;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik+Wet+Paint&display=swap');

* {
   -webkit-tap-highlight-color:transparent;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  background: #F5F5F5;
}
input{
  padding: 2px 10px;
}
div::-moz-selection { background:transparent; }
div::selection { background:transparent; }