.navbar {
  background: var(--deepGreen);
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}
.logo {
  font-size: 30px;
  color: aliceblue;
  font-style: italic;
}
.logos {
  font-weight: 400;
  font-family: sans-serif;
  font-size: 35px;
  text-align: center;
  letter-spacing: 3px;
  text-shadow: 2px 2px 0px #2d303a, -2px -2px 0px #2d303a, -2px 2px 0px #2d303a,
    2px -2px 0px #2d303a;
  color: #fff;
  cursor: pointer;
}
.searchBar {
  flex: 0.795;
  display: flex;
}
.navSearchBar {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  border: none;
  border-radius: 5px 0 0 5px;
}
.navSearchBar:focus-visible {
  outline: none;
}
.account-section {
  display: flex;
  align-items: center;
}
.account-section > * {
  margin-right: 15px;
  color: white !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.account-section > * > * {
  margin-right: 5px;
  color: white !important;
  cursor: pointer;
}
.searchIcon {
  all: initial;
  width: fit-content !important;
  background-color: white !important;
  padding: 0px 15px !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
  cursor: pointer !important;
  border: none !important;

}
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
nav ul {
  display: flex;
  gap: 4rem;
}
nav ul li {
  list-style-type: none;
  font-size: 16px;
  font-family: sans-serif;
}
nav ul li a {
  font-family: sans-serif;
}
a {
  color: white;
  font-style: sans-serif;
  text-decoration: none;
}
.icons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2rem;
  margin-bottom: 5px;
  position: relative;
  top: 6px;
  font-size: 1.4rem;
  color: aliceblue;
}
.icons a {
  color: none;
}
.account {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.account a {
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: 400;
}
.help {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.help a {
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: 400;
}
.cart {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.cart a {
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: 400;
}
@media  screen and (max-width: 400px) {
  .searchBar {
    width: 80px;
  }
  .navSearchBar {
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    border: none;
    border-radius: 5px 0 0 5px;
  }
  .logos {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 10vh;
  }
  .icons {
    display: flex;
    gap: 0.5rem;
  }
}
@media  screen and (max-width: 1000px) {
  .searchBar {
    width: 80px;
  }
  .navSearchBar {
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    border: none;
    border-radius: 5px 0 0 5px;
  }
  .logos {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 10vh;
  }
  .icons {
    display: flex;
    gap: 0.5rem;
  }
}
